@import '../../../../scss/theme-bootstrap';

$max-width-xlarge: 1920px;

.gnav-section {
  color: $color-white;
  padding: 20px 22px;
  @include breakpoint($large-up) {
    margin-#{$ldirection}: 20px;
    display: inline-block;
    padding: 0;
    &:first-child {
      margin-#{$ldirection}: 0;
    }
  }
  &.active.gnav-section--has-subcategory {
    background: $color-white;
    color: $color-black;
    @include breakpoint($large-up) {
      background: $color-black;
      color: $color-white;
    }
  }
  &__link-wrapper {
    @include text-link;
    @include text-link--style-1;
    text-decoration: none;
    color: $color-black;
    opacity: 1;
    display: block;
    font-family: $header-font-bold;
    letter-spacing: 0;
    padding: 0;
    position: relative;
    font-size: 24px;
    line-height: get-line-height(24px, 26px);
    @include breakpoint($large-up) {
      font-size: 14px;
      line-height: 1;
      padding: 0;
    }
    &:hover {
      color: $color-white;
      @include breakpoint($large-up) {
        color: $cr19-text-dark-gray;
        .gnav-section--has-subcategory & {
          color: $color-white;
        }
      }
    }
    &--top {
      color: $color-white;
      .gnav-section--has-subcategory & {
        @include breakpoint($large-up) {
          font-size: 14px;
        }
        // @todo replace with icon mixin when it's done.
        &:before {
          position: absolute;
          top: 50%;
          #{$rdirection}: 0;
          transform: translateY(-50%);
          display: inline-block;
          content: '';
          width: 20px;
          height: 20px;
          background: url('/media/images/global/icons/plus_white.svg') no-repeat center transparent;
          @include breakpoint($large-up) {
            background: none;
          }
        }
      }
      .active.gnav-section--has-subcategory & {
        color: $color-black;
        @include breakpoint($large-up) {
          color: $color-white;
        }
        // @todo replace with icon mixin when it's done.
        &:before {
          background-image: url('/media/images/global/icons/minus_black.svg');
          @include breakpoint($large-up) {
            background: none;
          }
        }
      }
      .section-dropdown-open .gnav-section:not(.active) & {
        opacity: 1;
        @include breakpoint($large-up) {
          opacity: 0.3;
        }
      }
      .section-dropdown-open .gnav-section:not(.gnav-section--has-subcategory) & {
        &:hover {
          opacity: 1;
        }
      }
    }
  }
  &__link {
    position: relative;
    color: $color-white;
    font-size: inherit;
    line-height: inherit;
    text-decoration: none;
    white-space: nowrap;
    &:hover {
      text-decoration: none;
      @include breakpoint($large-up) {
        color: $cr19-text-dark-gray;
      }
    }
    .active.gnav-section--has-subcategory & {
      color: $color-black;
      @include breakpoint($large-up) {
        color: $color-white;
        &:hover {
          color: $color-white;
        }
      }
    }
    .active:not(.gnav-section--has-subcategory) & {
      @include breakpoint($large-up) {
        color: $color-white;
        &:hover {
          color: $cr19-text-dark-gray;
        }
      }
    }
  }
  &__dropdown {
    @include transition(background 0.2s);
    &--top {
      background: $color-white;
      padding: 20px 0;
      @include breakpoint($large-up) {
        background: $color-black;
        position: fixed;
        text-align: $ldirection;
        #{$ldirection}: 0;
        width: 100%;
        min-height: 400px;
        padding: 0 30px 20px;
      }
    }
    &__link {
      border-top: 1px solid $color-white;
      max-width: $max-width-xlarge;
      text-align: $rdirection;
      color: $color-white;
      padding: 10px 0 0;
      display: block;
    }
    &__link a {
      color: $color-white;
      font-size: 14px;
      &:hover {
        color: $cr19-text-dark-gray;
      }
    }
    &__icon {
      &--close {
        position: absolute;
        #{$rdirection}: 0;
        top: -20px;
        z-index: 1;
        &::before {
          content: '';
          background: url('/media/images/global/icons/close_white.svg') no-repeat center transparent;
          width: 15px;
          height: 15px;
          display: inline-block;
        }
      }
    }
  }
  &__dropdown-wrapper {
    @include transition(opacity 0.4s ease-in-out, background 0.2s);
    margin: auto;
    color: $color-black;
    opacity: 0;
    height: 100%;
    position: relative;
    max-width: $max-width-xlarge;
    @include breakpoint($large-up) {
      padding-bottom: 15px;
      min-height: 350px;
      .content {
        display: flex;
        position: relative;
      }
    }
    .active & {
      opacity: 1;
    }
  }
}
